/**
 * @file Symmetry Constants
 * @author Alexander Rose <alexander.rose@weirdbyte.de>
 * @private
 */

export const SymOpCode: { [k: string]: string } = {
  ' ': 'X',
  '!': 'Y',
  '#': 'Z',
  '$': '-X',
  '%': '-Y',
  '&': '-Z',
  "'": 'Y+1/2',
  '(': '1/2+X',
  ')': '1/2+Y',
  '*': '1/2-X',
  '+': '1/2+Z',
  ',': '1/2-Y',
  '-': '1/2-Z',
  '.': 'X+1/2',
  '/': 'Z+1/2',
  '0': '-X+1/2',
  '1': '-Y+1/2',
  '2': '-Z+1/2',
  '3': '1/4+X',
  '4': '1/4-Y',
  '5': '1/4+Z',
  '6': '1/4-X',
  '7': '1/4+Y',
  '8': '3/4-Y',
  '9': '3/4+Z',
  ':': '3/4+Y',
  ';': '3/4+X',
  '<': '3/4-X',
  '=': '1/4-Z',
  '>': '3/4-Z',
  '?': 'X-Y',
  '@': 'Y-X',
  'A': 'Z+1/3',
  'B': 'Z+2/3',
  'C': 'X+2/3',
  'D': 'Y+1/3',
  'E': '-Y+2/3',
  'F': 'X-Y+1/3',
  'G': 'Y-X+2/3',
  'H': '-X+1/3',
  'I': 'X+1/3',
  'J': 'Y+2/3',
  'K': '-Y+1/3',
  'L': 'X-Y+2/3',
  'M': 'Y-X+1/3',
  'N': '-X+2/3',
  'O': '2/3+X',
  'P': '1/3+Y',
  'Q': '1/3+Z',
  'R': '2/3-Y',
  'S': '1/3+X-Y',
  'T': '2/3+Y-X',
  'U': '1/3-X',
  'V': '2/3-X',
  'W': '1/3-Y',
  'X': '1/3-Z',
  'Y': '2/3+Y',
  'Z': '1/3+Y-X',
  '[': '2/3+X-Y',
  ']': '1/3+X',
  '^': '2/3+Z',
  '_': '2/3-Z',
  '`': '5/6+Z',
  'a': '1/6+Z',
  'b': '5/6-Z',
  'c': '1/6-Z',
  'd': 'Z+5/6',
  'e': 'Z+1/6',
  'f': 'Z+1/4',
  'g': '+Y'
}

// encoded, originally from CCP4 symop.lib
export const EncodedSymOp: { [k: string]: string } = {
  'P 1': ' !#',
  'P -1': ' !#$%&',
  'P 1 2 1': ' !#$!&',
  'P 1 21 1': " !#$'&",
  'C 1 2 1': ' !#$!&()#*)&',
  'P 1 m 1': ' !# %#',
  'P 1 c 1': ' !# %+',
  'C 1 m 1': ' !# %#()#(,#',
  'C 1 c 1': ' !# %+()#(,+',
  'P 1 2/m 1': ' !# %#$!&$%&',
  'P 1 21/m 1': ' !#$)&$%& ,#',
  'C 1 2/m 1': ' !# %#$!&$%&()#(,#*)&*,&',
  'P 1 2/c 1': ' !#$!-$%& %+',
  'P 1 21/c 1': ' !#$%&$)- ,+',
  'C 1 2/c 1': ' !#$!-$%& %+()#*)-*,&(,+',
  'P 2 2 2': ' !#$%#$!& %&',
  'P 2 2 21': ' !#$%+$!- %&',
  'P 21 21 2': ' !#$%#*)&(,&',
  'P 21 21 21': ' !#*%+$)-(,&',
  'C 2 2 21': ' !#$%+$!- %&()#*,+*)-(,&',
  'C 2 2 2': ' !#$%#$!& %&()#*,#*)&(,&',
  'F 2 2 2': ' !#$%#$!& %& )+$,+$)- ,-(!+*%+*!-(%-()#*,#*)&(,&',
  'I 2 2 2': " !#$%# %&$!&.'/01/.120'2",
  'I 21 21 21': ' !#*%+$)-(,&()+$,#*!& %-',
  'P m m 2': ' !#$%# %#$!#',
  'P m c 21': ' !#$%+ %+$!#',
  'P c c 2': ' !#$%# %+$!+',
  'P m a 2': ' !#$%#(%#*!#',
  'P c a 21': ' !#$%+(%#*!+',
  'P n c 2': ' !#$%# ,+$)+',
  'P m n 21': ' !#*%+(%+$!#',
  'P b a 2': ' !#$%#(,#*)#',
  'P n a 21': ' !#$%+(,#*)+',
  'P n n 2': ' !#$%#(,+*)+',
  'C m m 2': ' !#$%# %#$!#()#*,#(,#*)#',
  'C m c 21': ' !#$%+ %+$!#()#*,+(,+*)#',
  'C c c 2': ' !#$%# %+$!+()#*,#(,+*)+',
  'A m m 2': ' !#$%# %#$!# )+$,+ ,+$)+',
  'A b m 2': ' !#$%# ,#$)# )+$,+ %+$!+',
  'A m a 2': ' !#$%#(%#*!# )+$,+(,+*)+',
  'A b a 2': ' !#$%#(,#*)# )+$,+(%+*!+',
  'F m m 2': ' !#$%# %#$!# )+$,+ ,+$)+(!+*%+(%+*!+()#*,#(,#*)#',
  'F d d 2': ' !#$%#345675 )+$,+3896:9(!+*%+;49<79()#*,#;85<:5',
  'I m m 2': ' !#$%# %#$!#()+*,+(,+*)+',
  'I b a 2': ' !#$%#(,#*)#()+*,+ %+$!+',
  'I m a 2': ' !#$%#(%#*!#()+*,+ ,+$)+',
  'P 2/m 2/m 2/m': ' !#$%#$!& %&$%& !& %#$!#',
  'P 2/n 2/n 2/n': ' !#$%#$!& %&*,-()-(,+*)+',
  'P 2/c 2/c 2/m': ' !#$%#$!- %-$%& !& %+$!+',
  'P 2/b 2/a 2/n': ' !#$%#$!& %&*,&()&(,#*)#',
  'P 21/m 2/m 2/a': ' !#*%#$!&(%&$%&(!& %#*!#',
  'P 2/n 21/n 2/a': ' !#*%#*)- ,-$%&(!&(,+$)+',
  'P 2/m 2/n 21/a': ' !#*%+*!- %&$%&(!-(%+$!#',
  'P 21/c 2/c 2/a': ' !#*%#$!-(%-$%&(!& %+*!+',
  'P 21/b 21/a 2/m': ' !#$%#*)&(,&$%& !&(,#*)#',
  'P 21/c 21/c 2/n': ' !#*,#$)-(%-$%&()& ,+*!+',
  'P 2/b 21/c 21/m': ' !#$%+$)- ,&$%& !- ,+$)#',
  'P 21/n 21/n 2/m': ' !#$%#*)-(,-$%& !&(,+*)+',
  'P 21/m 21/m 2/n': " !#$%#*'&.,&*,&.'& %#$!#",
  'P 21/b 2/c 21/n': ' !#*,+$!-(,&$%&()- %+*)#',
  'P 21/b 21/c 21/a': ' !#*%+$)-(,&$%&(!- ,+*)#',
  'P 21/n 21/m 21/a': " !#0%/$'&.12$%&.!2 1#0'/",
  'C 2/m 2/c 21/m': ' !#$%+$!- %&$%& !- %+$!#()#*,+*)-(,&*,&()-(,+*)#',
  'C 2/m 2/c 21/a': ' !#$,+$)- %&$%& )- ,+$!#()#*%+*!-(,&*,&(!-(%+*)#',
  'C 2/m 2/m 2/m': ' !#$%#$!& %&$%& !& %#$!#()#*,#*)&(,&*,&()&(,#*)#',
  'C 2/c 2/c 2/m': ' !#$%#$!- %-$%& !& %+$!+()#*,#*)-(,-*,&()&(,+*)+',
  'C 2/m 2/m 2/a': ' !#$,#$)& %&$%& )& ,#$!#()#*%#*!&(,&*,&(!&(%#*)#',
  'C 2/c 2/c 2/a': ' !#*,#$!&(,&$,-(!- ,+*!+()#$%#*)& %&*%- )-(%+$)+',
  'F 2/m 2/m 2/m': ' !#$%#$!& %&$%& !& %#$!# )+$,+$)- ,-$,- )- ,+$)+(!+*%+*!-(%-*%-(!-(%+*!+()#*,#*)&(,&*,&()&(,#*)#',
  'F 2/d 2/d 2/d': ' !#$%#$!& %&64=37=345675 )+$,+$)- ,-68>3:>3896:9(!+*%+*!-(%-<4>;7>;49<79()#*,#*)&(,&<8=;:=;85<:5',
  'I 2/m 2/m 2/m': ' !#$%#$!& %&$%& !& %#$!#()+*,+*)-(,-*,-()-(,+*)+',
  'I 2/b 2/a 2/m': ' !#$%#*)&(,&$%& !&(,#*)#()+*,+$!- %-*,-()- %+$!+',
  'I 21/b 21/c 21/a': ' !#*%+$)-(,&$%&(!- ,+*)#()+$,#*!& %-*,- )&(%#$!+',
  'I 21/m 21/m 21/a': ' !#$,#$)& %&$%& )& ,#$!#()+*%+*!-(,-*,-(!-(%+*)+',
  'P 4': ' !#$%#% #!$#',
  'P 41': ' !#$%+% 5!$9',
  'P 42': ' !#$%#% +!$+',
  'P 43': ' !#$%+% 9!$5',
  'I 4': ' !#$%#% #!$#()+*,+,(+)*+',
  'I 41': ' !#*,+%(5)$9()+$%#, 9!*5',
  'P -4': ' !#$%#!$&% &',
  'I -4': ' !#$%#!$&% &()+*,+)*-,(-',
  'P 4/m': ' !#$%#% #!$#$%& !&!$&% &',
  'P 42/m': ' !#$%#% +!$+$%& !&!$-% -',
  'P 4/n': ' !#$%#,(#)*#*,&()&!$&% &',
  'P 42/n': ' !#$%#,(+)*+*,-()-!$&% &',
  'I 4/m': ' !#$%#% #!$#$%& !&!$&% &()+*,+,(+)*+*,-()-)*-,(-',
  'I 41/a': ' !#*,+%(5)$9$,=(!>!$&,(-()+$%#, 9!*5*%> )=)*-% &',
  'P 4 2 2': ' !#$%#% #!$#$!& %&! &%$&',
  'P 4 21 2': ' !#$%#,(#)*#*)&(,&! &%$&',
  'P 41 2 2': ' !#$%+% 5!$9$!& %-! >%$=',
  'P 41 21 2': ' !#$%+,(5)*9*)=(,>! &%$-',
  'P 42 2 2': ' !#$%#% +!$+$!& %&! -%$-',
  'P 42 21 2': ' !#$%#,(+)*+*)-(,-! &%$&',
  'P 43 2 2': ' !#$%+% 9!$5$!& %-! =%$>',
  'P 43 21 2': ' !#$%+,(9)*5*)>(,=! &%$-',
  'I 4 2 2': ' !#$%#% #!$#$!& %&! &%$&()+*,+,(+)*+*)-(,-)(-,*-',
  'I 41 2 2': ' !#*,+%(5)$9*!> ,=)(-%$&()+$%#, 9!*5$)=(%>! &,*-',
  'P 4 m m': ' !#$%#% #!$# %#$!#%$#! #',
  'P 4 b m': ' !#$%#% #!$#(,#*)#,*#)(#',
  'P 42 c m': ' !#$%#% +!$+ %+$!+%$#! #',
  'P 42 n m': ' !#$%#,(+)*+(,+*)+%$#! #',
  'P 4 c c': ' !#$%#% #!$# %+$!+%$+! +',
  'P 4 n c': ' !#$%#% #!$#(,+*)+,*+)(+',
  'P 42 m c': ' !#$%#% +!$+ %#$!#%$+! +',
  'P 42 b c': ' !#$%#% +!$+(,#*)#,*+)(+',
  'I 4 m m': ' !#$%#% #!$# %#$!#%$#! #()+*,+,(+)*+(,+*)+,*+)(+',
  'I 4 c m': ' !#$%#% #!$# %+$!+%$+! +()+*,+,(+)*+(,#*)#,*#)(#',
  'I 41 m d': ' !#*,+%(5)$9 %#*)+%*5) 9()+$%#, 9!*5(,+$!#,$9!(5',
  'I 41 c d': ' !#*,+%(5)$9 %+*)#%*9) 5()+$%#, 9!*5(,#$!+,$5!(9',
  'P -4 2 m': ' !#$%#% &!$&$!& %&%$#! #',
  'P -4 2 c': ' !#$%#% &!$&$!- %-%$+! +',
  'P -4 21 m': ' !#$%#% &!$&*)&(,&,*#)(#',
  'P -4 21 c': ' !#$%#% &!$&*)-(,-,*+)(+',
  'P -4 m 2': ' !#$%#!$&% & %#$!#! &%$&',
  'P -4 c 2': ' !#$%#% &!$& %+$!+! -%$-',
  'P -4 b 2': ' !#$%#% &!$&(,#*)#)(&,*&',
  'P -4 n 2': ' !#$%#% &!$&(,+*)+)(-,*-',
  'I -4 m 2': ' !#$%#% &!$& %#$!#! &%$&()+*,+,(-)*-(,+*)+)(-,*-',
  'I -4 c 2': ' !#$%#% &!$& %+$!+! -%$-()+*,+,(-)*-(,#*)#)(&,*&',
  'I -4 2 m': ' !#$%#% &!$&$!& %&%$#! #()+*,+,(-)*-*)-(,-,*+)(+',
  'I -4 2 d': ' !#$%#% &!$&*!>(%>,$9) 9()+*,+,(-)*-$)= ,=%*5!(5',
  'P 4/m 2/m 2/m': ' !#$%#% #!$#$!& %&! &%$&$%& !&!$&% & %#$!#%$#! #',
  'P 4/m 2/c 2/c': ' !#$%#% #!$#$!- %-! -%$-$%& !&!$&% & %+$!+%$+! +',
  'P 4/n 2/b 2/m': ' !#$%#% #!$#$!& %&! &%$&*,&()&)*&,(&(,#*)#,*#)(#',
  'P 4/n 2/n 2/c': ' !#$%#% #!$#$!& %&! &%$&*,-()-)*-,(-(,+*)+,*+)(+',
  'P 4/m 21/b 2/m': ' !#$%#% #!$#*)&(,&)(&,*&$%& !&!$&% &(,#*)#,*#)(#',
  'P 4/m 21/n 2/c': ' !#$%#% #!$#*)-(,-)(-,*-$%& !&!$&% &(,+*)+,*+)(+',
  'P 4/n 21/m 2/m': ' !#$%#,(#)*#*)&(,&! &%$&*,&()&!$&% & %#$!#,*#)(#',
  'P 4/n 2/c 2/c': ' !#$%#,(#)*#*)-(,-! -%$-*,&()&!$&% & %+$!+,*+)(+',
  'P 42/m 2/m 2/c': ' !#$%#% +!$+$!& %&! -%$-$%& !&!$-% - %#$!#%$+! +',
  'P 42/m 2/c 2/m': ' !#$%#% +!$+$!- %-! &%$&$%& !&!$-% - %+$!+%$#! #',
  'P 42/n 2/b 2/c': ' !#$%#,(+)*+$!- %-)(&,*&*,-()-!$&% &(,#*)#%$+! +',
  'P 42/n 2/n 2/m': ' !#$%#,(+)*+$!& %&)(-,*-*,-()-!$&% &(,+*)+%$#! #',
  'P 42/m 21/b 2/c': ' !#$%#% +!$+*)&(,&)(-,*-$%& !&!$-% -(,#*)#,*+)(+',
  'P 42/m 21/n 2/m': " !#$%#,./'*/*'-.,-! &%$&$%& !&'*-,.-.,/*'/%$#! #",
  'P 42/n 21/m 2/c': ' !#$%#,(+)*+*)-(,-! &%$&*,-()-!$&% & %#$!#,*+)(+',
  'P 42/n 21/c 2/m': ' !#$%#,(+)*+*)&(,&! -%$-*,-()-!$&% & %+$!+,*#)(#',
  'I 4/m 2/m 2/m': ' !#$%#% #!$#$!& %&! &%$&$%& !&!$&% & %#$!#%$#! #()+*,+,(+)*+*)-(,-)(-,*-*,-()-)*-,(-(,+*)+,*+)(+',
  'I 4/m 2/c 2/m': ' !#$%#% #!$#$!- %-! -%$-$%& !&!$&% & %+$!+%$+! +()+*,+,(+)*+*)&(,&)(&,*&*,-()-)*-,(-(,#*)#,*#)(#',
  'I 41/a 2/m 2/d': ' !#*,+%(5)$9*!> ,=)(-%$&$,=(!>!$&,(-(,+$!#,$9!(5()+$%#, 9!*5$)=(%>! &,*-*%> )=)*-% & %#*)+%*5) 9',
  'I 41/a 2/c 2/d': ' !#*,+%(5)$9*!= ,>)(&%$-$,=(!>!$&,(-(,#$!+,$5!(9()+$%#, 9!*5$)>(%=! -,*&*%> )=)*-% & %+*)#%*9) 5',
  'P 3': ' !#%?#@$#',
  'P 31': ' !#%?A@$B',
  'P 32': ' !#%?B@$A',
  'H 3': ' !#%?#@$#CDAEFAGHAIJBKLBMNB',
  'R 3': ' !## !!# ',
  'P -3': ' !#%?#@$#$%&!@&? &',
  'H -3': ' !#%?#@$#$%&!@&? &OPQRSQTUQVWXYZX[]X]Y^W[^ZV^UR_PT_SO_',
  'R -3': ' !## !!# $%&&$%%&$',
  'P 3 1 2': ' !#%?#@$#%$&@!& ?&',
  'P 3 2 1': ' !#%?#@$#! &?%&$@&',
  'P 31 1 2': ' !#%?Q@$^%$_@!X ?&',
  'P 31 2 1': ' !#%?A@$B! &?%_$@X',
  'P 32 1 2': ' !#%?^@$Q%$X@!_ ?&',
  'P 32 2 1': ' !#%?B@$A! &?%X$@_',
  'H 3 2': ' !#%?#@$#! &?%&$@&OPQRSQTUQY]X[WXVZX]Y^W[^ZV^PO_SR_UT_',
  'R 3 2': ' !## !!# %$&$&%&%$',
  'P 3 m 1': ' !#%?#@$#%$#@!# ?#',
  'P 3 1 m': ' !#%?#@$#! #?%#$@#',
  'P 3 c 1': ' !#%?#@$#%$+@!+ ?+',
  'P 3 1 c': ' !#%?#@$#! +?%+$@+',
  'H 3 m': ' !#%?#@$#%$#@!# ?#OPQRSQTUQRUQTPQOSQ]Y^W[^ZV^WV^ZY^][^',
  'R 3 m': ' !## !!# ! # #!#! ',
  'H 3 c': ' !#%?#@$#%$+@!+ ?+OPQRSQTUQRU`TP`OS`]Y^W[^ZV^WVaZYa][a',
  'R 3 c': " !## !!# '././'/'.",
  'P -3 1 2/m': ' !#%?#@$#%$&@!& ?&$%&!@&? &! #?%#$@#',
  'P -3 1 2/c': ' !#%?#@$#%$-@!- ?-$%&!@&? &! +?%+$@+',
  'P -3 2/m 1': ' !#%?#@$#! &?%&$@&$%&!@&? &%$#@!# ?#',
  'P -3 2/c 1': ' !#%?#@$#! -?%-$@-$%&!@&? &%$+@!+ ?+',
  'H -3 2/m': ' !#%?#@$#! &?%&$@&$%&!@&? &%$#@!# ?#OPQRSQTUQY]X[WXVZXVWXYZX[]XRUQTPQOSQ]Y^W[^ZV^PO_SR_UT_UR_PT_SO_WV^ZY^][^',
  'R -3 2/m': ' !## !!# %$&$&%&%$$%&&$%%&$! # #!#! ',
  'H -3 2/c': ' !#%?#@$#! -?%-$@-$%&!@&? &%$+@!+ ?+OPQRSQTUQY]b[WbVZbVWXYZX[]XRU`TP`OS`]Y^W[^ZV^POcSRcUTcUR_PT_SO_WVaZYa][a',
  'R -3 2/c': " !## !!# 102021210$%&&$%%&$'././'/'.",
  'P 6': ' !#%?#@$#$%#!@#? #',
  'P 61': ' !#%?A@$B$%/!@d? e',
  'P 65': ' !#%?B@$A$%/!@e? d',
  'P 62': ' !#%?^@$Q$%#!@^? Q',
  'P 64': ' !#%?Q@$^$%#!@Q? ^',
  'P 63': ' !#%?#@$#$%+!@+? +',
  'P -6': ' !#%?#@$# !&%?&@$&',
  'P 6/m': ' !#%?#@$#$%#!@#? #$%&!@&? & !&%?&@$&',
  'P 63/m': ' !#%?#@$#$%+!@+? +$%&!@&? & !-%?-@$-',
  'P 6 2 2': ' !#%?#@$#$%#!@#? #! &?%&$@&%$&@!& ?&',
  'P 61 2 2': ' !#%?Q@$^$%+!@`? a! X?%&$@_%$b@!- ?c',
  'P 65 2 2': ' !#%?^@$Q$%+!@a? `! _?%&$@X%$c@!- ?b',
  'P 62 2 2': ' !#%?^@$Q$%#!@^? Q! _?%&$@X%$_@!& ?X',
  'P 64 2 2': ' !#%?Q@$^$%#!@Q? ^! X?%&$@_%$X@!& ?_',
  'P 63 2 2': ' !#%?#@$#$%+!@+? +! &?%&$@&%$-@!- ?-',
  'P 6 m m': ' !#%?#@$#$%#!@#? #%$#@!# ?#! #?%#$@#',
  'P 6 c c': ' !#%?#@$#$%#!@#? #%$+@!+ ?+! +?%+$@+',
  'P 63 c m': ' !#%?#@$#$%+!@+? +%$+@!+ ?+! #?%#$@#',
  'P 63 m c': ' !#%?#@$#$%+!@+? +%$#@!# ?#! +?%+$@+',
  'P -6 m 2': ' !#%?#@$# !&%?&@$&%$#@!# ?#%$&@!& ?&',
  'P -6 c 2': ' !#%?#@$# !-%?-@$-%$+@!+ ?+%$&@!& ?&',
  'P -6 2 m': ' !#%?#@$# !&%?&@$&! &?%&$@&! #?%#$@#',
  'P -6 2 c': ' !#%?#@$# !-%?-@$-! &?%&$@&! +?%+$@+',
  'P 6/m 2/m 2/m': ' !#%?#@$#$%#!@#? #! &?%&$@&%$&@!& ?&$%&!@&? & !&@$&%?&%$#@!# ?#! #?%#$@#',
  'P 6/m 2/c 2/c': ' !#%?#@$#$%#!@#? #! -?%-$@-%$-@!- ?-$%&!@&? & !&@$&%?&%$+@!+ ?+! +?%+$@+',
  'P 63/m 2/c 2/m': ' !#%?#@$#$%+!@+? +! -?%-$@-%$&@!& ?&$%&!@&? & !-@$-%?-%$+@!+ ?+! #?%#$@#',
  'P 63/m 2/m 2/c': ' !#%?#@$#$%+!@+? +! &?%&$@&%$-@!- ?-$%&!@&? & !-@$-%?-%$#@!# ?#! +?%+$@+',
  'P 2 3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ',
  'F 2 3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%&  )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-((!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&(()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- ',
  'I 2 3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ()+*,+*)-(,-+()+*,-*)-(,)+(,+*)-*,-(',
  'P 21 3': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(',
  'I 21 3': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(()+$,#*!& %-+()#$,&*!- %)+(,#$!&*%- ',
  'P 2/m -3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& $%& !& %#$!#&$%& !# %#$!%&$!& %# !#$',
  'P 2/n -3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& *,-()-(,+*)+-*,-()+(,+*),-*)-(,+()+*',
  'F 2/m -3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& $%& !& %#$!#&$%& !# %#$!%&$!& %# !#$ )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-($,- )- ,+$)+&*,&()#(,#*)%-*!-(%+(!+*(!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&(*%-(!-(%+*!+-$,- )+ ,+$),&*)&(,#()#*()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- *,&()&(,#*)#-*%-(!+(%+*!,-$)- ,+ )+$',
  'F 2/d -3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& 64=37=345675=64=375345674=67=3453756 )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-(68>3:>3896:9=<8=;:5;85<:4><7>;49;79<(!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&(<4>;7>;49<79>68>3:93896:8=<:=;85;:5<()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- <8=;:=;8f<:f><4>;79;49<78>6:>3893:96',
  'I 2/m -3': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& $%& !& %#$!#&$%& !# %#$!%&$!& %# !#$()+*,+*)-(,-+()+*,-*)-(,)+(,+*)-*,-(*,-()-(,+*)+-*,-()+(,+*),-*)-(,+()+*',
  'P 21/a -3': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&($%&(!- ,+*)#&$%-(!+ ,#*)%&$!-(,+ )#*',
  'I 21/a -3': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&($%&(!- ,+*)#&$%-(!+ ,#*)%&$!-(,+ )#*()+$,#*g& %-+()#$,&*!- %)+(,#$!&*%- *,- )&(%#$!+-*,& )#(%+$!,-*)& %#(!+$',
  'P 4 3 2': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$',
  'P 42 3 2': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,*',
  'F 4 3 2': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$ )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-(!(-%*-!*+%(+ +,$+)$-, -)#)*#,(&)(&,*(!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&() -,$-)$+, +(#,*#)*&,(&)+!*+%(-!(-%*()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- )(&,*&)*#,(#(+%*+!*-%(-!+)$+, -) -,$',
  'F 41 3 2': ' !#$,+*)&(%-# !+$,&*)-(%!# ,+$)&*%-(:3>46=7<98;5;58<976=43>:97<58;>:3=46 )+$%#*!-(,&#()+*%&$!- ,!+(,#*)-$%& :;=4<>765839;94<5:6>83=79:6543>7;=8<(!+*,#$)- %&+ )#$%-*!&(,)#(%+*!&$,- 73=86>:<54;935469:<=8;>7576983=:;>4<()#*%+$!& ,-+(!#*,-$)& %)+ %#$!-*,&(7;>8<=:69435398657<>4;=:5:<94;=73>86',
  'I 4 3 2': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$()+*,+*)-(,-+()+*,-*)-(,)+(,+*)-*,-()(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,*',
  'P 43 3 2': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(7;>46=:<5839398<5:6=4;>75:<983>7;=46',
  'P 41 3 2': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(:3=8<>7694;5;54697<>83=:97654;=:3>8<',
  'I 41 3 2': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(:3=8<>7694;5;54697<>83=:97654;=:3>8<()+$,#*!& %-+()#$,&*!- %)+(,#$!&*%- 7;>46=:<5839398<5:6=4;>75:<983>7;=46',
  'P -4 3 m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! #%$#!$&% & #!$#%$&! &%#! #%$&!$&% ',
  'F -4 3 m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! #%$#!$&% & #!$#%$&! &%#! #%$&!$&%  )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-(!(+%*+!*-%(- +)$+,$-) -,#)(#,*&)*&,((!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&() +,$+)$-, -(#)*#,*&)(&,+!(+%*-!*-%(()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- )(#,*#)*&,(&(+!*+%*-!(-%+) +,$-)$-, ',
  'I -4 3 m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! #%$#!$&% & #!$#%$&! &%#! #%$&!$&% ()+*,+*)-(,-+()+*,-*)-(,)+(,+*)-*,-()(+,*+)*-,(-(+)*+,*-)(-,+)(+,*-)*-,(',
  'P -4 3 n': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(+,*+)*-,(-(+)*+,*-)(-,+)(+,*-)*-,(',
  'F -4 3 c': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(+,*+)*-,(-(+)*+,*-)(-,+)(+,*-)*-,( )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-() #,$#)$&, &(#!*#%*&!(&%+! +%$-!$-% (!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&(!(#%*#!*&%(& +!$+%$-! -%#) #,$&)$&, ()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- ! +%$+!$-% - #)$#,$&) &,#!(#%*&!*&%(',
  'I -4 3 d': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(7354<9:6>8;=357<946>:;=857394<>:6=8;()+$,#*!& %-+()#$,&*!- %)+(,#$!&*%- :;98657<=43>;9:658<=73>49:;586=7<>43',
  'P 4/m -3 2/m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$$%& !& %#$!#&$%& !# %#$!%&$!& %# !#$%$#! #% &!$&$&! &% #!$#%&% &!$#%$#! ',
  'P 4/n -3 2/n': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$*,-()-(,+*)+-*,-()+(,+*),-*)-(,+()+*,*+)(+,(-)*-*-)(-,(+)*+,-,(-)*+,*+)(',
  'P 42/m -3 2/n': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,*$%& !& %#$!#&$%& !# %#$!%&$!& %# !#$,*+)(+,(-)*-*-)(-,(+)*+,-,(-)*+,*+)(',
  'P 42/n -3 2/m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,**,-()-(,+*)+-*,-()+(,+*),-*)-(,+()+*%$#! #% &!$&$&! &% #!$#%&% &!$#%$#! ',
  'F 4/m -3 2/m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$$%& !& %#$!#&$%& !# %#$!%&$!& %# !#$%$#! #% &!$&$&! &% #!$#%&% &!$#%$#!  )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-(!(-%*-!*+%(+ +,$+)$-, -)#)*#,(&)(&,*$,- )- ,+$)+&*,&()#(,#*)%-*!-(%+(!+*%*+!(+%(-!*-$-) -, +)$+,&,(&)*#,*#)((!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&() -,$-)$+, +(#,*#)*&,(&)+!*+%(-!(-%**%-(!-(%+*!+-$,- )+ ,+$),&*)&(,#()#*,$+) +, -)$-*&)(&,(#)*#,-%(-!*+%*+!(()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- )(&,*&)*#,(#(+%*+!*-%(-!+)$+, -) -,$*,&()&(,#*)#-*%-(!+(%+*!,-$)- ,+ )+$,*#)(#,(&)*&*-!(-%(+!*+%-, -)$+,$+) ',
  'F 4/m -3 2/c': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& )(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,*$%& !& %#$!#&$%& !# %#$!%&$!& %# !#$,*+)(+,(-)*-*-)(-,(+)*+,-,(-)*+,*+)( )+$,+$)- ,-#()#*,&*)&(,!+(%+*!-*%-() &,$&)$#, #(#%*#!*&%(&!+!$+% -! -%$$,- )- ,+$)+&*,&()#(,#*)%-*!-(%+(!+*,$#) #, &)$&*&!(&%(#!*#%-% -!$+%$+! (!+*%+*!-(%-+ )+$,-$)- ,)#(,#*)&*,&(!(&%*&!*#%(# +%$+!$-% -!#)$#, &) &,$*%-(!-(%+*!+-$,- )+ ,+$),&*)&(,#()#*%*#!(#%(&!*&$-! -% +!$+%&, &)$#,$#) ()#*,#*)&(,&+(!+*%-*!-(%)+ ,+$)-$,- ! -%$-!$+% + #,$#)$&, &)#!*#%(&!(&%**,&()&(,#*)#-*%-(!+(%+*!,-$)- ,+ )+$%$+! +% -!$-$&) &, #)$#,&%(&!*#%*#!(',
  'F 41/d -3 2/m': ' !#$,+*)&(%-# !+$,&*)-(%!# ,+$)&*%-(:3>46=7<98;5;58<976=43>:97<58;>:3=4664=3:>;85<79=64>3:5;89<74=6:>385;79<,$+! #%(-)*&*&)(-% #!$+,-%(&)*+,$#!  )+$%#*!-(,&#()+*%&$!- ,!+(,#*)-$%& :;=4<>765839;94<5:6>83=79:6543>7;=8<68>37=;49<:5=<8>;753496:4><:=;893756,*#!(+% &)$-*-!(&, +)$#%-, &!$+%*#)((!+*,#$)- %&+ )#$%-*!&(,)#(%+*!&$,- 73=86>:<54;935469:<=8;>7576983=:;>4<<4>;:=389675>68=379;45<:8=<7>;453:96%$#) +,(&!*-$&! -,(#)*+%&% -)$#,*+!(()#*%+$!& ,-+(!#*,-$)& %)+ %#$!-*,&(7;>8<=:69435398657<>4;=:5:<94;=73>86<8=;7>3456:9><4=;:9385678>67=349;:5<%*+)(#, -!$&$-) &%(+!*#,&,(-!*#%$+) ',
  'F 41/d -3 2/c': ' !#$,+*)&(%-# !+$,&*)-(%!# ,+$)&*%-(:3>46=7<98;5;58<976=43>:97<58;>:3=46<8>;7=3496:5><8=;793456:8><7=;493:56%*#)(+, &!$-$-! &,(+)*#%&, -!$#%*+)( )+$%#*!-(,&#()+*%&$!- ,!+(,#*)-$%& :;=4<>765839;94<5:6>83=79:6543>7;=8<<4=;:>385679>64=3:9;85<78=67>345;:9<%$+) #,(-!*&$&) -%(#!*+,&%(-)*#,$+! (!+*,#$)- %&+ )#$%-*!&(,)#(%+*!&$,- 73=86>:<54;935469:<=8;>7576983=:;>4<68=37>;45<:9=<4>;:5389674>6:=389;75<,*+!(#% -)$&*-)(&% +!$#,-,(&!*+%$#) ()#*%+$!& ,-+(!#*,-$)& %)+ %#$!-*,&(7;>8<=:69435398657<>4;=:5:<94;=73>8664>3:=;89<75=68>375;49<:4=<:>;853796,$#! +%(&)*-*&!(-, #)$+%-% &)$+,*#!(',
  'I 4/m -3 2/m': ' !#$%#$!& %&# !#$%&$!& %!# %#$!&$%& ! &%$&!$#% # #%$#!$&% &!#!$#% &! &%$$%& !& %#$!#&$%& !# %#$!%&$!& %# !#$%$#! #% &!$&$&! &% #!$#%&% &!$#%$#! ()+*,+*)-(,-+()+*,-*)-(,)+(,+*)-*,-()(-,*-)*+,(+(+,*+)*-,(-)+)*+,(-)(-,**,-()-(,+*)+-*,-()+(,+*),-*)-(,+()+*,*+)(+,(-)*-*-)(-,(+)*+,-,(-)*+,*+)(',
  'I 41/a -3 2/d': ' !#*%+$)-(,&# !+*%-$)&(,!# %+*)-$,&(:3=8<>7694;5;54697<>83=:97654;=:3>8<$%&(!- ,+*)#&$%-(!+ ,#*)%&$!-(,+ )#*4<97358;=:6>6>:;=8357<94=8;>:694<573()+$,#*!& %-+()#$,&*!- %)+(,#$!&*%- 7;>46=:<5839398<5:6=4;>75:<983>7;=46*,- )&(%#$!+-*,& )#(%+$!,-*)& %#(!+$865:;943>7<=<=73>4;9:658>43=7<5869:;',
  'P 1 1 2': ' !#$%#',
  'P 1 1 21': ' !#$%+',
  'B 1 1 2': ' !#$%#(g+*%+',
  'A 1 2 1': ' !#$!& )+$)-',
  'C 1 21 1': ' !#$)&()#*!&',
  'I 1 2 1': " !#$!&.'/0'2",
  'I 1 21 1': " !#$)&.'/0!-",
  'P 1 1 m': ' !# !&',
  'P 1 1 b': ' !# )&',
  'B 1 1 m': ' !# !&(!+(!-',
  'B 1 1 b': ' !# )&(!+()-',
  'P 1 1 2/m': ' !# !&$%#$%&',
  'P 1 1 21/m': ' !#$%+$%& !-',
  'B 1 1 2/m': ' !# !&$%#$%&(!+(!-*%+*%-',
  'P 1 1 2/b': ' !#$,#$%& )&',
  'P 1 1 21/b': ' !#$%&$,+ )-',
  'B 1 1 2/b': ' !#$,#$%& )&(!+*,+*%-()-',
  'P 21 2 2': ' !#$!&(%&*%#',
  'P 2 21 2': ' !# ,&$)&$%#',
  'P 21 21 2 (a)': " !#*,#.%&$'&",
  'P 21 2 21': ' !#$!&(%-*%+',
  'P 2 21 21': ' !# %&$)-$,+',
  'C 2 2 21a)': ' !#*%+(,&$)-()#$,+ %&*!-',
  'C 2 2 2a': " !#*,#.%&$'&()#$%# ,&*!&",
  'F 2 2 2a': " !#*,#.%&$'& '/*%/.12$!2.!/$,/ %20'2.'#$%# 1&0!&",
  'I 2 2 2a': " !#*,#.%&$'&()+$%+*!- ,-",
  'P 21/m 21/m 2/n a': " !#*,#$)&(%&$%&.'& ,#*!#",
  'P 42 21 2a': " !#*,#%.+'$+$'&.%&! -,*-",
  'I 2 3a': " !#*,#.%&$'&!# ,- '&$%/$# !-*!/$%&.%()+$%+ ,-*!-)+(%&(!-*,#*+()&$)#*,- ,"
}
